/******************/
/**** vertical ***/
/****************/

.iconsMenu {
  display: grid;
  background-color: var(--dark);
  /* font-size: 1.5rem; */
  width: 100%;
  gap: 2rem;
  height: 100%;
  border-top: 2px solid var(--darker);
  margin-bottom: 0.5rem;

  padding: 1rem 0;
  grid-template-rows: 3rem 3rem 3rem 1fr 3rem;
  grid-template-areas:
    "home"
    "projects"
    "contact"
    "."
    "about";
}
.iconsSegment {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--dark);
}

.iconsSegment img:hover {
  width: 3rem;
  transition: all 0.5s 0s;
  border-left: 3px white solid;
  padding-left: 0.3rem;
}

.iconsSegment img {
  width: 2.5rem;
}

.iconsOption {
  color: white;
  text-decoration: none;
  background-color: transparent;
}

.iconsSegment:hover {
  background-color: var(--brigth);
}
.iconsOptionSelected {
  transition: all 0.5s 0s;
}

.iconsOptionSelected img {
  width: 3rem;
  border-left: 3px white solid;
  border-radius: 28%;
  padding-left: 0.3rem;
  transition: all 0.5s 0s;
}

.iconsMenu :nth-child(1) {
  grid-area: home;
}

.iconsMenu :nth-child(2) {
  grid-area: about;
}

.iconsMenu :nth-child(3) {
  grid-area: projects;
}

.iconsMenu :nth-child(4) {
  grid-area: contact;
}

.iconsMenu :nth-child(5) {
  grid-area: settings;
}
