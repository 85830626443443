.projects h1 {
  font-size: 3rem;
  margin: 4rem 1rem;
  color: var(--remark);
}

.posterContainer {
  position: relative;
  width: 16rem;
}
.poster {
  border-radius: 20px;
  width: 16rem;
  height: 16rem;
  position: relative;
}

.posterInfo {
  width: 16rem;
  height: 16rem;
  background-color: black;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 1rem;
  padding-top: 2rem;
  gap: 2rem;
  top: 0;
  position: absolute;
}
.tecIcon {
  width: 2rem;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
}

.projectTecnologies {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.showPicture {
  display: initial;
  animation-name: appear;
  animation-delay: 0s;
  animation-duration: 1s;
}

.showDetails {
  display: flex;
  animation-name: appear;
  animation-delay: 0s;
  animation-duration: 1s;
}
.hidePicture,
.hideDetails {
  animation-name: disappear;
  animation-delay: 0.5s;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.none {
  display: none;
}

.buttonContainer {
  position: absolute;
  bottom: 1rem;
  display: flex;
  gap: 1rem;
  width: 100%;
  justify-content: center;
}

.btn_projectIcons {
  font-size: 2.2rem;
}
button.btn_projectIcons {
  height: 4rem;
  width: 4rem;
  text-align: center;
  border: 2px solid var(--remark);
  border-radius: 50%;
}

.btn_projectIcons {
  animation-name: blink;
  animation-delay: 0;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.IconButton {
  border-radius: 50%;
}

@keyframes appear {
  0% {
    transform: translateY(0px) scale(1, 0);
    transform-origin: 50% top;
    opacity: 0.5;
  }
  100% {
    transform: translateY(0px) scale(1, 1);
    transform-origin: 50% top;
    opacity: 1;
  }
}

@keyframes disappear {
  0% {
    transform: translateY(0px) scale(1, 1);
    opacity: 0.5;
    transform-origin: 50% top;
  }
  100% {
    transform: translateY(16rem) scale(1, 0);
    opacity: 0;
    transform-origin: 50% top;
  }
}

@keyframes blink {
  0% {
    transform: rotate(0) scale(0.95, 0.95);
    box-shadow: 0 0 0 0px var(--remark);
  }
  50% {
    transform: rotate(45deg) scale(1, 1);
    box-shadow: 0 0 10px 3px var(--remark);
  }

  100% {
    transform: rotate(0) scale(0.95, 0.95);
    box-shadow: 0 0 0 0px var(--remark);
  }
}
