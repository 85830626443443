.aboutContainer {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--brigth);
  overflow: hidden;
}
.about {
  /* display: flex;
  flex-direction: column; */
  width: 100%;
  display: grid;
  gap: 3rem;
  grid-template-rows: 5rem 4rem 1fr;
  grid-template-areas:
    "title"
    "type"
    "text";
}

.bgTitle {
  position: absolute;
  top: -5%;
  left: 10%;
  font-size: 15rem;
  color: var(--remark);
  opacity: 0.1;
  text-orientation: sideways-right;
}
.about h1 {
  font-size: 3rem;
  margin: 4rem 1rem;
  color: var(--remark);
  grid-area: title;
}

.infoTextAbout {
  font-size: 1rem;
  margin: 2rem 1rem;
  margin-top: 1rem;
  z-index: 1;
}
.textContainer {
  grid-area: text;
}
.typeContainer {
  display: flex;
  justify-content: center;
  grid-area: type;
}

.Typewriter__wrapper {
  display: inline;
  font-size: 2rem;
  display: flex;
  flex-wrap: wrap;
}

span.typeCursor {
  color: white;
  font-size: 2rem;
}

.infoTextAbout span {
  color: var(--highlight);
}
@media (min-width: 500px) {
  .infoTextAbout {
    font-size: 1.5rem;
  }
}

/* @media (min-width: 1000px) {
  .infoTextAbout {
    font-size: 2.5rem;
  }
} */
