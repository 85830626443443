.projectsContainer {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--brigth);
  overflow: auto;
}
.projects {
  position: relative;
  display: flex;
  flex-direction: column;
  position: relative;
}

.bgTitle {
  position: absolute;
  top: -5%;
  left: 10%;
  font-size: 15rem;
  color: var(--remark);
  opacity: 0.1;
  text-orientation: sideways-right;
}

.listProjects {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 3rem;
  max-width: 100%;
  padding: 0 2rem;
}
