/*******-*****/
/** GENERAL **/
/*************/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  --dark: #1f2428;
  --brigth: #24292e;
  --darker: #161a1d;
  --brigther: #2b2e32;
  --remark: #857ee2;
  --highlight: rgb(204, 0, 126);
}

.App {
  background-color: white;
  width: 100vw;
  height: 100vh;
  display: grid;
  background-color: black;
  /* margin: auto; */
  grid-template-columns: 1fr;
  grid-template-rows: 2rem 2rem 1fr 4rem;
  grid-template-areas:
    "  fakeHeader"
    "  menu    "
    "  main    "
    "  footer  ";
}

.App > * {
  color: white;
}

.fakeHeader {
  grid-area: fakeHeader;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--dark);
}

.menu {
  grid-area: menu;
}

.icons {
  display: none;
  flex-direction: column;
  align-items: center;
  grid-area: icons;
  background-color: var(--dark);
  border-right: 2px solid var(--darker);
  padding-bottom: 1rem;
}

.icons > img:hover {
  width: 3rem;
  transition: all 0.5s 0s;
  border-left: 3px white solid;

  padding-left: 0.3rem;
}
.settingSelected {
  width: 3rem;
  transition: all 0.5s 0s;
  border-left: 3px white solid;
  border-radius: 28%;
  padding-left: 0.3rem;
}

.settingNotSelected {
  width: 2.5rem;
  transition: all 0.5s 0s;
}

.menuSideBar {
  display: none;
}

.main {
  grid-area: main;
  background-color: var(--brigth);
  border-left: 2px solid var(--darker);
  overflow: scroll;
}

.footer {
  grid-area: footer;
  background-color: var(--brigth);
  border: 2px solid var(--darker);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
}

.sideBarContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 1rem;
}
.arrow {
  width: 1.5rem;
}
.down {
  transform: rotate(90deg);
  transition: transform 0.5s 0s;
}
.right {
  transform: rotate(0deg);
  transition: transform 0.5s 0s;
}

.show {
  width: 100%;
  transform: scale(1, 1);
  transform-origin: 50% top;
  transition: all 0.2s 0s;
}

.hide {
  transform: scale(1, 0);
  transform-origin: 50% top;
  transition: all 0.2s 0s;
}

.titleCursor {
  color: white;
  margin-left: 0.5rem;
}

@media (min-width: 500px) {
  .icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-area: icons;
    background-color: var(--dark);
    border-right: 2px solid var(--darker);
    padding-bottom: 1rem;
  }

  .App {
    width: 100vw;
    height: 100vh;
    display: grid;
    background-color: black;
    margin: auto;
    grid-template-columns: 4rem 5fr;
    grid-template-rows: 2rem 2.5rem 1fr 4rem;
    grid-template-areas:
      "fakeHeader  fakeHeader"
      "icons  menu    "
      "icons  main    "
      "footer  footer  ";
  }
}

@media (min-width: 1040px) {
  .menuSideBar {
    display: flex;
    flex-direction: column;
    grid-area: verticalMenu;
    background-color: var(--dark);
    border-top: 2px solid var(--darker);
    align-items: flex-start;
    /* padding: 1rem 2rem; */
    width: 100%;
  }

  .menuSideBar > P {
    padding: 1rem 1rem;
  }
  .App {
    grid-template-columns: 4rem 10rem 10fr;
    grid-template-rows: 2rem 2.5rem 1fr 2rem;
    grid-template-areas:
      "fakeHeader fakeHeader fakeHeader   "
      "icons verticalMenu menu    "
      "icons verticalMenu main    "
      "footer footer footer  ";
  }
}
