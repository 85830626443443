/******************/
/**** vertical ***/
/****************/

.verticalMenu {
  display: flex;
  flex-direction: column;
  background-color: var(--dark);
  font-size: 1.5rem;
  width: 100%;
  gap: 0.5rem;
}
.verticalSegment {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--dark);
  padding: 0rem 1.5rem;
  transition: all 0.5s 0s;
  width: 100%;
  gap: 0.5rem;
}

.verticalSegment img {
  width: 1.3rem;
}

.verticalOption {
  color: white;
  text-decoration: none;
  background-color: transparent;
  font-size: 1rem;
}

.verticalSegment:hover {
  background-color: var(--brigth);
}
.verticalOptionSelected {
  transition: all 0.5s 0s;
}
