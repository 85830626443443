.homeContainer {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--brigth);
  overflow: hidden;
}
.home {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.bgTitle {
  position: absolute;
  top: -5%;
  left: 10%;
  font-size: 15rem;
  color: var(--remark);
  opacity: 0.1;
  text-orientation: sideways-right;
}
.home h1 {
  font-size: 3rem;
  margin: 4rem 1rem;
  color: var(--remark);
}

.show {
  display: initial;
}
.hide {
  display: none;
}
.infoTextHome {
  font-size: 1rem;
  margin: 2rem 1rem;
  margin-top: 1rem;
  z-index: 1;
}
.infoTextHome span {
  color: var(--highlight);
}
@media (min-width: 500px) {
  .infoTextHome {
    font-size: 1.5rem;
  }
}

/* @media (min-width: 1024px) {
  .infoTextHome {
    font-size: 2.5rem;
  }
} */
