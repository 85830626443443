/*******-*****/
/**** MENU ***/
/*************/

.mainMenu {
  display: flex;
  background-color: var(--dark);
  font-size: 2rem;
  border-top: 2px solid var(--darker);
  border-left: 2px solid var(--darker);
}
.menuSegment {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.5px solid var(--brigth);
  border-left: 2.5px solid var(--brigth);
  border-right: 2.5px solid var(--brigth);
  background-color: var(--dark);
  padding: 0rem 0.8rem;
  transition: all 0.5s 0s;
  gap: 0.5rem;
}

.menuSegment img {
  width: 1.7rem;
}

.menuOption {
  color: white;
  text-decoration: none;
  background-color: transparent;
}

.menuSegment:hover {
  background-color: var(--brigth);
}
.menuOptionSelected {
  background-color: var(--brigth);
  border-top: 2px solid var(--remark);
  transition: all 0.5s 0s;
}
